.sua-loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  z-index: 2005;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

body div.dropdown {
  position: initial;
}

.input-group-text.disabled {
  background-color: #e9ecef;
  opacity: 1;
}

#season > div {
  width: 8.2em;
}

div.activationLink {
  background: #f2f2f2;
  text-align: center;
  margin: 14px 0;
  padding: 20px 0;
  font-weight: bold;
  border-radius: 5px;
}

#payment-filter {
  width: 11em;
}